import React, { useState } from "react";
import Routes from "../../Routes";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const GalleriNavbar = ({ color, textColor, option }) => {
  const [MenuIsOpen, setMenuIsOpen] = useState(false);
  const menuHandler = (e) => {
    e.preventDefault();
    MenuIsOpen ? setMenuIsOpen(false) : setMenuIsOpen(true);
  };

  return (
    <div
    style={{ background:"#000000" }}
      className={`bg-${color} text-gray-${textColor} md:flex font-regular md:items-center md:justify-center text-gray-50`}
    >
      <div className="md:flex hidden relative z-10 flex flex-row items-baseline justify-between py-4">
        {/* <a href="/" className="text-xl font-semibold hover:text-gray-200">
          Fresh Renhold
        </a> */}
        <div className="flex flex-row text-md justify-center items-baseline">
          <Link
            to="/"
            style={{}}
            className="hover:bg-white hover:bg-opacity-20 py-2 px-4"
          >
            {option}
          </Link>
          <Link
            to="/contact"
            style={{}}
            className="ml-16 hover:bg-white hover:bg-opacity-20 py-2 px-4"
          >
            Kontakt
          </Link>
        </div>
        {/* <div className="flex flex-row text-lg">
        </div> */}
      </div>
      {/* lg above */}

      {/* mobila below */}
      <div
      style={{ background:"#000000" }}
        className="md:hidden relative z-10 py-3 mb-10 flex flex-col px-4"
      >
        {/* logo + hamburger */}

        <div className="flex flex-row items-center justify-between">
          <p className="text-2xl font-semibold">Fresh Renhold</p>
          {/* setup dugme da se otvara meni na klik */}
          <button className="focus:outline-none" onClick={menuHandler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        <div
          className={`mobile-menu ${
            MenuIsOpen ? "" : "hidden"
          } z-10 relative flex flex-col items-start px-0 mt-3`}
        >
          <Link
            to="/"
            className="text-lg font-regular px-1 py-1 rounded-md hover:bg-black hover:bg-opacity-10"
          >
            {option}
          </Link>
          <Link
            to="/contact"
            className="text-lg font-regular px-1 py-1 rounded-md hover:bg-black hover:bg-opacity-10"
          >
            Kontakt
          </Link>
        </div>
      </div>
      <Routes />
    </div>
  );
};

export default GalleriNavbar;
