import React from "react";
import Checkmark from "../Checkmark";
import ContactButton from "../ContactButton";
import Navbar from "../Navbar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Visningsvask = () => {
  return (
    <div>
      <Navbar option="Hjem" color="black" textColor="white" />
      <div className="flex items-center flex-col justify-center min-h-screen">
        <div className="md:flex hidden -mt-32 flex-col items-center justify-center shadow-md px-10 pb-5">
          <p className="text-3xl font-bold mt-40">Visningsvask</p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex mb-6">
              <div className="flex flex-col items-center">
                <img
                  style={{ height: "200px" }}
                  src="https://images.unsplash.com/photo-1496092607007-ca127e0b6a10?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=785&q=80"
                  className="w-96"
                  alt=""
                />
                <p className="w-96 text-justify mt-3">
                  Skal du selge bolig og ønsker å gjøre et godt førsteinntrykk
                  av boligen under visning? Dette kan vi hjelpe deg med.
                  Visningsvask inkluderer oppfriskning av bolig gjennom å tørke
                  støv, vaske gulv, rydde bort søppel og annet som påvirker
                  salget på en dårlig måte. Ved behov kan vi også vaske
                  terrasse. For vask før visning av din bolig tilbyr vi
                  konkurransedyktige priser. Endelig pris avhenger av boligens
                  størrelse, og hvilke konkrete forespørsler du har i
                  forbindelse med vasken i tillegg til det som nevnes her.
                  Ønsker du et pristilbud kan du henvende deg gjennom
                  kontaktskjemaet vårt.
                </p>
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
          </div>
        </div>
        <div className="md:block pb-5 hidden">
          <ContactButton />
        </div>
        {/* gore big */}
        {/* dolje small */}
        <div className="md:hidden -mt-32 flex-col items-center justify-center shadow-md px-10 mt-5 pb-5">
          <p className="text-3xl font-bold mt-28 mb-5 text-center">
          Visningsvask
          </p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex flex-col mb-6">
              <div className="flex flex-col items-center">
                <img
                  style={{ height: "200px" }}
                  src="https://images.unsplash.com/photo-1496092607007-ca127e0b6a10?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=785&q=80"
                  className="w-72"
                  alt=""
                />
                <p className="w-72 text-justify mt-3">
                  Skal du selge bolig og ønsker å gjøre et godt førsteinntrykk
                  av boligen under visning? Dette kan vi hjelpe deg med.
                  Visningsvask inkluderer oppfriskning av bolig gjennom å tørke
                  støv, vaske gulv, rydde bort søppel og annet som påvirker
                  salget på en dårlig måte. Ved behov kan vi også vaske
                  terrasse. For vask før visning av din bolig tilbyr vi
                  konkurransedyktige priser. Endelig pris avhenger av boligens
                  størrelse, og hvilke konkrete forespørsler du har i
                  forbindelse med vasken i tillegg til det som nevnes her.
                  Ønsker du et pristilbud kan du henvende deg gjennom
                  kontaktskjemaet vårt.
                </p>
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
          </div>
        </div>
        <div className="md:hidden pb-5">
          <ContactButton />
        </div>
      </div>
    </div>
  );
};

export default Visningsvask;
