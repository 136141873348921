import React from "react";
import Checkmark from "../Checkmark";
import ContactButton from "../ContactButton";
import Navbar from "../Navbar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const KidsRoom = () => {
  return (
    <div>
      <Navbar option="Hjem" color="black" textColor="white" />
      <div className="flex items-center flex-col justify-center min-h-screen">
        <div className="md:flex hidden -mt-32 flex-col items-center justify-center shadow-md px-10 pb-10">
          <p className="text-2xl w-4/5 text-center font-bold mt-16">
            Rengjøring av barnerom med sortering av leke
          </p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex mb-6">
              <div className="flex flex-col items-center">
                <img
                  style={{ height: "200px" }}
                  src="https://images.unsplash.com/photo-1548096027-926a68d14d95?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2045&q=80"
                  className="w-96"
                  alt=""
                />
                <p className="w-96 text-justify mt-3">
                  Skal du bruke terrassen/balkongen mye? Vi kan tilby deg å
                  rydde vekk søppel, vaske terrasse samt vaske hagemøbler og
                  eventuelt puter/trekk på disse møblene. Vi strekker oss langt
                  for at du skal kunne kose deg litt ekstra i sommer med familie
                  og venner.
                </p>
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
          </div>
        </div>
        <div className="md:block pb-5 hidden">
          <ContactButton />
        </div>
        {/* gore big */}
        {/* dolje small */}
        <div className="md:hidden -mt-32 flex-col items-center justify-center shadow-md px-10 mt-5 pb-5">
          <p className="text-3xl font-bold mt-28 mb-5 text-center">
            Rengjøring av barnerom med sortering av leke
          </p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex flex-col mb-6">
              <div className="flex flex-col items-center">
                <img
                  style={{ height: "200px" }}
                  src="https://images.unsplash.com/photo-1548096027-926a68d14d95?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2045&q=80"
                  className="w-72"
                  alt=""
                />
                <p className="w-72 text-justify mt-3">
                  Skal du bruke terrassen/balkongen mye? Vi kan tilby deg å
                  rydde vekk søppel, vaske terrasse samt vaske hagemøbler og
                  eventuelt puter/trekk på disse møblene. Vi strekker oss langt
                  for at du skal kunne kose deg litt ekstra i sommer med familie
                  og venner.
                </p>
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
          </div>
        </div>
        <div className="md:hidden pb-5">
          <ContactButton />
        </div>
      </div>
    </div>
  );
};

export default KidsRoom;
