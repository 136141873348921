import React from "react";
import Checkmark from "../Checkmark";
import ContactNavbar from "../ContactNavbar";
import ContactFormSpecial from "../../containers/ContactFormSpecial";

const Bedriftsrengjoring = () => {
  return (
    <div>
      <div>
        <ContactFormSpecial />
      </div>
    </div>
  );
};

export default Bedriftsrengjoring;
