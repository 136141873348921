import React from "react";
import { Link } from "react-router-dom";

const ContactButton = () => {
  return (
    <div>
      <Link to="/contact">
        <button
          class="shadow bg-gray-900 hover:bg-black focus:shadow-outline
          focus:outline-none text-white font-bold py-2 px-4 mt-5"
        >
          Bestill
        </button>
      </Link>
    </div>
  );
};

export default ContactButton;
