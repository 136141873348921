import React from "react";
import { Link } from "react-router-dom";

const ServiceCardNew = ({ img, title, details, price, slash, tagText }) => {
  return (
    <Link to={slash}>
      <div className="bg-white relative border-black border-2 flex flex-col w-72 shadow-md hover:shadow-xl h-80 mx-5 my-5 ovf-hide">
        <svg
          version="1.1"
          baseProfile="full"
          width="250"
          height="40"
          xlmns="http://www/w3/org/2000/svg"
          className="absolute z-0 mt-5 -ml-16 transform -rotate-45 text-white"
        >
          <rect className="" width="100%" height="100%" fill="black"></rect>
        </svg>
        <p className="absolute ml-6 mt-8 z-10 transform -rotate-45 text-white">{tagText}</p>
        <img src={img} className="inset-7 bg-black object-cover h-44  mb-3" />
        <div className="p-3 bg-white">
          <p className="font-bold text-lg">{title}</p>
          <p className="text-xs my-3 ml-1">{details}</p>
          <div className="flex flex-row items-center">
            <p className="text-sm font-semibold">fra</p>
            <p className="text-lg font-semibold mx-1">{price}</p>
            <p className="text-sm font-semibold">nok</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ServiceCardNew;
