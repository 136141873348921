import React from "react";
import { Link as ScrollLink } from "react-scroll";
import LearnMore from "./LearnMore";

const Header = () => {
  return (
    <div style={{ background: "#000000" }} id="Header">
      <div className="md:block hidden">
        <div>
          <img
            src="https://images.unsplash.com/photo-1541123437800-1bb1317badc2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt=""
            className="absolute inset-0 h-full w-full object-cover darken"
          />
        </div>
        <div className="relative z-10 text-gray-50 min-h-screen mt-10 flex flex-col items-center justify-center">
          {/*  */}
          <div className="flex flex-row justify-center items-center">
            <div className="w-7/12 text-center">
              <p className="text-4xl font-bold">
                “En ren start er alltid en god start”
              </p>
              <p className="text-xl font-regular mt-10 mb-72 text-center">
                Velkommen til oss! Her finner du våre tjenester som skal hjelpe
                å avlaste deg, slik at du får bedre tid til familie og venner,
                eller alt annet som du ikke får tid til i dag.
              </p>
            </div>
          </div>
          {/*  */}
          <ScrollLink to="LearnMore" smooth="true">
            <div className="flex flex-col items-center cursor-pointer -mt-40">
              <p className="hover:underline text-lg hover:pointer mb-4">
                Les mer
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 vert-move"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                />
              </svg>
            </div>
          </ScrollLink>
        </div>
        <div className="absolute z-10">
          <LearnMore />
        </div>
      </div>
      {/* above big */}

      {/* below small */}
      <div className="md:hidden text-gray-50">
        <div className="absolute z-0">
          <img
            className="min-h-screen max-h-screen object-cover w-full -mt-24 darken_mobile"
            src="https://images.unsplash.com/photo-1556910585-09baa3a3998e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80"
            alt=""
          />
        </div>
        <div className="relative z-10 flex flex-col justify-center items-center">
          <div className="w-9/12 text-center ">
            <p className="text-3xl font-bold">
              “En ren start er alltid en god start”
            </p>
            <p className="text-xl font-regular mt-10 mb-20 text-center">
              Velkommen til oss! Her finner du våre tjenester som skal hjelpe å
              avlaste deg, slik at du får bedre tid til familie og venner, eller
              alt annet som du ikke får til i dag.
            </p>
          </div>
        </div>
        <ScrollLink to="lol" smooth="true">
          <div className="relative z-10 flex flex-col items-center cursor-pointer">
            <p className="hover:underline text-lg hover:pointer mb-4">
              Les mer
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 vert-move"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
              />
            </svg>
          </div>
        </ScrollLink>
      </div>
      <div
        id="lol"
        className="realative z-10"
        style={{ background: "#000000" }}
      >
        <LearnMore />
      </div>
    </div>
  );
};

export default Header;
