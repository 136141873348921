import React from "react";
import GalleriNavbar from '../components/GalleriNavbar'
const Galleri = () => {
  return (
    <div>
        <GalleriNavbar option="Home" />
      <div className="flex flex-col min-h-screen items-center justify-center">
        <p className="font-bold text-4xl">Her kommer bilder fra tidligere oppdrag.</p>
      </div>
    </div>
  );
};

export default Galleri;
