import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div
      id="contacts"
      style={{ background: "#000000" }}
      className="min-h-screen text-gray-50 flex flex-col items-center justify-between md:pb-36 md:pt-36 pt-10"
    >
      <p className="text-5xl font-semibold flex items-center justify-center flex-col">
        Kontakt oss
        <p className="text-sm font-light md:mb-16 mt-3 mb-20">Org.Nr.: 926070991</p>
      </p>

      <div className="md:flex md:flex-row md:ml-24 flex flex-col md:items-baseline items-center justify-between">
        <div className="flex flex-col items-center text-center md:mb-0 mb-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-24 w-24 mb-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <p>TELEFON</p>
          <p className="text-sm">
            Man-Fre 07:00 - 16:00
            <br />
            48661886
          </p>
        </div>
        <Link to="/contact">
          <div className="flex flex-col text-center items-center mx-20 md:mb-0 mb-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-24 w-24 mb-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <p>E-MAIL</p>
            <p className="text-sm md:w-80">
              Vi vil gjerne vite hvordan vi kan hjelpe deg. Her kan du ta
              kontakt og beskrive dine behov.
            </p>
          </div>
        </Link>
        <div className="flex flex-col items-center text-center ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-24 w-24 mb-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <p className="uppercase">Følg oss på sosiale medier</p>
          <p className="text-sm">
            <a
              className="hover:underline"
              target="_blank"
              href="https://www.facebook.com/freshdrammen/"
            >
              Facebook
            </a>
          </p>
        </div>
      </div>
      <ScrollLink to="NavbarHome" smooth="true">
        <div className="flex flex-col items-center cursor-pointer md:mb-0 mt-8 mb-10">
          <p className="hover:underline hover:pointer mb-4 text-gray-50">
            Gå tilbake
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 text-gray-50 vert-move"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 11l7-7 7 7M5 19l7-7 7 7"
            />
          </svg>
        </div>
      </ScrollLink>
    </div>
  );
};

export default Contact;
