import React from "react";

const CheckmarkPlus = ({ text, position }) => {
  return (
    <div
      className={`flex flex-row items-${position} text-left flex-shrink-0 flex-wrap mb-7`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-7 w-7 mr-2 text-green-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <p className="text-xs md:text-sm" style={{ width: "70%" }}>
        {text}
      </p>
    </div>
  );
};

export default CheckmarkPlus;
