import "./App.css";
import { Route, BrowserRouter as Router, Link } from "react-router-dom";
import Navbar from "../src/app/components/Navbar";
import NavbarColored from "../src/app/components/NavbarColored";
import BigServiceCard from "../src/app/components/BigServiceCard";
import Services from "./app/containers/Services";
import ServicesLonger from "./app/containers/ServicesLonger";
import Home from "./app/containers/Home";
import Header from "./app/components/Header";
import Bedriftsrengjoring from "./app/components/services/Bedriftsrengjoring";
import Daglig from "./app/components/services/Daglig";
import Flyttevask from "./app/components/services/Flyttevask";
import Hovedrengjoring from "./app/components/services/Hovedrengjoring";
import Spesial from "./app/components/services/Spesial";
import KidsRoom from "./app/components/services/KidsRoom";
import Terrase from "./app/components/services/Terrase";
import Visningsvask from "./app/components/services/Visningsvask";
import Galleri from "./app/containers/Galleri";
import ContactForm from "./app/containers/ContactForm";

function App() {
  return (
    <Router>
      <div className="font-noto">
        <Route exact path="/" component={Home} />
        <Route exact path="/daglig_renhold" component={Daglig} />
        <Route exact path="/flyttevask" component={Flyttevask} />
        <Route exact path="/hovedrengjoring" component={Hovedrengjoring} />
        <Route exact path="/bedriftsrengjoring" component={Bedriftsrengjoring} /> 
        <Route exact path="/visningsvask" component={Visningsvask} /> 
        <Route exact path="/terrase" component={Terrase} /> 
        <Route exact path="/spesial" component={Spesial} /> 
        <Route exact path="/barnerom" component={KidsRoom} /> 
        <Route exact path="/galleri" component={Galleri} /> 
        <Route exact path="/contact" component={ContactForm} /> 
      </div>
    </Router>
  );
}

export default App;
