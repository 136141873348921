import React from "react";
import Checkmark from "../Checkmark";
import ContactButton from "../ContactButton";
import Navbar from "../Navbar";

const Flyttevask = () => {
  return (
    <div>
      <Navbar option="Hjem" color="black" textColor="white" />
      <div className="flex items-center justify-center min-h-screen flex-col">
        <div className="md:flex hidden -mt-32 flex-col items-center justify-center shadow-md px-10 pb-5">
          <p className="text-3xl font-bold mt-16 mb-10">Flyttevask</p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex">
              <div className="flex flex-col">
                <img
                  src="https://images.unsplash.com/photo-1613668816690-546c6fa9ad42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                  className="w-96"
                  alt=""
                />
                <p className="w-96 text-justify mt-3">
                  Denne pakken av renholdstjenester trenger du ikke gjøre en
                  separat bestilling på vask av kjøleskap/komfyr og vinduer.
                </p>
              </div>
              <div className="w-96 md:ml-32">
                <Checkmark
                  position="center"
                  text="Detaljvask av bad som inkluderer alle fliser på gulv og vegg, toalettskål, dusj og/eller badekar, skap inn- og utvendig, vask"
                />
                <Checkmark
                  position="center"
                  text="Kjøkkenskap inn- og utvendig, vask av kjøleskap, vask av komfyr samt andre hvitevarer inn- og utvendig."
                />
                <Checkmark
                  position="center"
                  text="Vask av vegg (inkluderer ikke fjerning av vanskelige flekker)"
                />
                <Checkmark
                  position="center"
                  text="Støvsuging og vask av alle gulv"
                />
                <Checkmark
                  position="center"
                  text="Vask av vinduer inn- og utvendig (opptil 3m høyde)"
                />
                <Checkmark
                  position="center"
                  text="Vask/tørking av stikkontakter og lister."
                />
                <Checkmark position="center" text="Vask av dører og karmer" />
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
            <div></div>
          </div>
        </div>
        <div className="md:block hidden">
          <ContactButton />
        </div>
        {/* gore big */}
        {/* dolje small */}
        <div className="md:hidden flex flex-col items-center pb-10">
          <div className="flex flex-col items-center justify-center">
            <p className="text-3xl font-semibold mb-8">Flyttevask</p>
            <img
              style={{ width: "16rem" }}
              className=""
              src="https://images.unsplash.com/photo-1613668816690-546c6fa9ad42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
              alt=""
            />
            <p
              style={{ width: "16rem" }}
              className="mt-2 mb-5 text-sm text-justify"
            >
              Denne pakken av renholdstjenester trenger du ikke gjøre en
              separat bestilling på vask av kjøleskap/komfyr og vinduer.
            </p>
            <p className="text-xl font-bold">Hva du får</p>
            <div style={{ width: "16rem" }} className="mt-5 flex flex-col">
              <Checkmark
                position="center"
                text="Detaljvask av bad som inkluderer alle fliser på gulv og vegg, toalettskål, dusj og/eller badekar, skap inn- og utvendig, vask"
              />
              <Checkmark
                position="center"
                text="Kjøkkenskap inn- og utvendig, vask av kjøleskap, vask av komfyr samt andre hvitevarer inn- og utvendig."
              />
              <Checkmark
                position="center"
                text="Vask av vegg (inkluderer ikke fjerning av vanskelige flekker)"
              />
              <Checkmark
                position="center"
                text="Støvsuging og vask av alle gulv"
              />
              <Checkmark
                position="center"
                text="Vask av vinduer inn- og utvendig (opptil 3m høyde)"
              />
              <Checkmark
                position="center"
                text="Vask/tørking av stikkontakter og lister."
              />
              <Checkmark position="center" text="Vask av dører og karmer" />
            </div>
          </div>
          <div className="md:hidden">
          <ContactButton />
        </div>
        </div>
      </div>
    </div>
  );
};

export default Flyttevask;
