import React, { useState } from "react";
import ContactNavbar from "../components/ContactNavbar";
import emailjs from "emailjs-com";
import { useHistory } from "react-router";

const ContactFormSpecial = () => {
  const [sent, setSent] = useState(false);
  const history = useHistory()
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_53kvtqk",
        "template_cvtcvcu",
        e.target,
        "user_B8l8g9coQRfzAinogEYIy"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setSent(true);
    history.push('/') 
  }

  return (
    <div>
      <ContactNavbar />
      {sent ? (
        <div className="min-h-screen font-bold text-4xl flex mt-24 justify-center">
          <p>We'll stay in touch&nbsp;</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={4}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      ) : (
        <div className="min-h-screen flex flex-col items-center justify-center md:mt-10 -mt-24 md:px-0 pb-5 px-8">
          <p className="font-bold text-4xl mb-16">Send oss forespørsel</p>
          <form onSubmit={sendEmail} class="w-full max-w-lg">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-password"
                >
                  Navn
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name"
                  type="text"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-password"
                >
                  E-post
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="email"
                  type="email"
                />
              </div>
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-password"
                >
                  Emne
                </label>

                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="subject"
                  type="text"
                  value="Bedriftsrengjøring"
                />
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-password"
                >
                  Melding
                </label>
                <textarea
                  class=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                  name="message"
                ></textarea>
              </div>
            </div>
            <div class="md:flex md:items-center">
              <div class="md:w-1/3"></div>
              <div class="md:w-2/3"></div>
            </div>
            <input
              class="shadow bg-gray-900 hover:bg-black focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 "
              type="submit"
              value="Sende"
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactFormSpecial;
