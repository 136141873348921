import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./app/containers/Home";
import NotFound from "./app/containers/NotFound";

export default function Routes() {
  return (
    <Switch>

    </Switch>
  );
}