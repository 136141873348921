import React from "react";
import Header from '../components/Header'
import NavbarHome from '../components/NavbarHome'
import Services from '../containers/Services'
import Contact from '../containers/Contact'

const Home = () => {
  return (
    <div>
        <NavbarHome option="Våre tjenester" />
        <Header />
        <Services />
        <Contact />
    </div>
  );
};

export default Home;
