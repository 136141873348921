import React from "react";
import Checkmark from "../Checkmark";
import ContactButton from "../ContactButton";
import Navbar from "../Navbar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Daglig = () => {
  return (
    <div>
      <Navbar option="Hjem" color="black" textColor="white" />
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="md:flex hidden -mt-32 flex-col items-center justify-center shadow-md px-10 pb-5">
          <p className="text-3xl font-bold mt-16 mb-10">Daglig renhold</p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex">
              <div className="flex flex-col">
                <img
                  src="https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=967&q=80"
                  className="w-96"
                  alt=""
                />
                <p className="w-96 text-justify mt-3">
                  Vi kan også tilby generell rengjøring som et pakketilbud som
                  kan bestilles i tillegg til enkelte tjenester. For mer info
                  sjekk &nbsp;
                  <Link
                    className="font-bold hover:text-blue-500"
                    to="/hovedrengjoring"
                  >
                    her
                  </Link>
                  .
                </p>
              </div>
              <div className="w-96 md:ml-32">
                <Checkmark
                  position="center"
                  text="Støvtørking fra alle overflater"
                />
                <Checkmark
                  position="center"
                  text="Vasking/tørking av speil og bilder"
                />
                <Checkmark
                  position="center"
                  text="Bytting av sengetøy/Re opp seng(er)"
                />
                <Checkmark
                  position="center"
                  text="Vasking/tørking av alle overflater på kjøkken og skap innvending."
                />
                <Checkmark
                  position="center"
                  text="Vasking av alle hvitevarer og kjøkkenutstyr inn- og utvendig."
                />
                <Checkmark
                  position="center"
                  text="Vask av kraner på bad og kjøkken"
                />
                <Checkmark
                  position="center"
                  text="Støvsuging og vask av gulv og lister."
                />
                <Checkmark
                  position="center"
                  text="Vask av bad som inkluderer vask av badekar eller dusj, vask av fliser innvendig i dusj, vask av toalettskål, vasking av vask, gulv, speil eller glass, skap utvendig"
                />
                <Checkmark
                  position="center"
                  text="Kasting av søppel fra alle rom."
                />
                <Checkmark
                  position="center"
                  text="Vask/flekkfjerning fra dører."
                />
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
            <div></div>
          </div>
        </div>
        <div className="md:block hidden">
          <ContactButton />
        </div>
        {/* gore big */}
        {/* dolje small */}
        <div className="md:hidden flex flex-col pb-10">
          <div className="flex flex-col items-center justify-center">
            <p className="text-3xl font-semibold mb-8">Daglig renhold</p>
            <img
              style={{ width: "16rem" }}
              className=""
              src="https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=967&q=80"
              alt=""
            />
            <p
              style={{ width: "16rem" }}
              className="mt-2 mb-8 text-sm text-justify"
            >
              Vi kan også tilby generell rengjøring som et pakketilbud som kan
              bestilles i tillegg til enkelte tjenester. For mer info sjekk{" "}
              <Link className="font-bold text-blue-500" to="/">
                her
              </Link>
              .
            </p>
            <p className="text-xl font-bold">What you get:</p>
            <div style={{ width: "16rem" }} className="mt-5 flex flex-col">
              <Checkmark
                position="center"
                text="Støvtørking fra alle overflater"
              />
              <Checkmark
                position="center"
                text="Vasking/tørking av speil og bilder"
              />
              <Checkmark
                position="center"
                text="Bytting av sengetøy/Re opp seng(er)"
              />
              <Checkmark
                position="center"
                text="Vasking/tørking av alle overflater på kjøkken og skap innvending."
              />
              <Checkmark
                position="center"
                text="Vasking av alle hvitevarer og kjøkkenutstyr inn- og utvendig."
              />
              <Checkmark
                position="center"
                text="Vask av kraner på bad og kjøkken"
              />
              <Checkmark
                position="center"
                text="Støvsuging og vask av gulv og lister."
              />
              <Checkmark
                position="center"
                text="Vask av bad som inkluderer vask av badekar eller dusj, vask av fliser innvendig i dusj, vask av toalettskål, vasking av vask, gulv, speil eller glass, skap utvendig."
              />
              <Checkmark
                position="center"
                text="Kasting av søppel fra alle rom."
              />
              <Checkmark
                position="center"
                text="Vask/flekkfjerning fra dører."
              />
            </div>
            <div className="md:hidden">
              <ContactButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Daglig;
