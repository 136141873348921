import React from "react";
import { Link } from 'react-router-dom'

const BusinessCard = ({ img, title, details, price, slash }) => {
  return (
    <Link to={slash}>
      <div className="bg-white border-black border-2 flex flex-col w-72 shadow-md hover:shadow-xl h-80 mx-5 my-5">
        <img src={img} className="inset-0 object-cover h-44  mb-3" />
        <div className="p-3">
          <p className="font-bold text-lg">{title}</p>
          <p className="text-xs my-3 ml-1">{details}</p>
          <div className="flex flex-row items-center">
            <p className="text-md font-semibold mx-1">Pris etter avtale</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BusinessCard;
