import React from "react";
import { Link as ScrollLink } from "react-scroll";
const LearnMore = () => {
  return (
    <div id="LearnMore">
      <div className="md:block hidden">
        <div>
          <div
            style={{ background: "#000000" }}
            className="min-h-screen flex text-gray-50 flex-col text-center items-center justify-center"
          >
            <p className="font-bold text-4xl mb-20">Om oss</p>
            <p className="w-6/12 leading-7 mb-10 text-lg md:pb-24">
              Trenger du hjelp med rengjøring? Da er du kommet riktig. Denne
              nyoppstartede bedriften ledes av Marija som har lang erfaring i
              bransjen, både fra privat og offentlig Vi tilbyr alt innen
              rengjøring i Drammen og omegn. Rent hjem og arbeidsplass sørger
              for et hyggelig opphold og en dose serotonin som vi gjerne gir deg
              i hverdagen. Det eneste du trenger å gjøre er å bestille våre
              tjenester, og vi sørger for å møte dine forventninger så langt som
              det er mulig. Fleksibilitet og effektivitet er det vi alltid
              garanterer gjennom vår tilfredsgaranti. Vi har stor lidenskap for
              å hjelpe bedrifter og privatpersoner med å rydde tid til å gjøre
              det de er gode på og brenner for, og så tar vi oss av å holde det
              rent og pent. Hos oss møter dere alltid hyggelige og
              serviceinstilte renholdere som setter kunden på første plass. Vi
              ønsker å skille oss ut fra alle andre renholds bedrifter med
              tjenester vi tilbyr.
            </p>
          </div>
        </div>
        <ScrollLink to="services" smooth="true">
          <div className="flex flex-col items-center cursor-pointer pb-40 -mt-32">
            <p className="hover:underline hover:pointer mb-4 text-gray-50">
              Våre tjenester
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 vert-move text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
              />
            </svg>
          </div>
        </ScrollLink>
      </div>
      {/* above big */}

      {/* below small */}
      <div className="md:hidden block mt-28">
        <div
          style={{ background: "#000000" }}
          className="relative z-10 min-h-screen flex text-gray-50 flex-col text-center items-center justify-center -mb-28"
        >
          <p className="font-bold text-3xl mb-6">Om oss</p>
          <p className="w-9/12 leading-5 mb-32 text-xs text-justify">
            Trenger du hjelp med rengjøring? Da er du kommet riktig. Denne
            nyoppstartede bedriften ledes av Marija som har lang erfaring i
            bransjen, både privat og offentlig. Vi tilbyr alt innen rengjøring i
            Drammen og omegn. Rent hjem og arbeidsplass sørger for et hyggelig
            opphold og en dose serotonin som vi gjerne gir deg i hverdagen. Det
            eneste du trenger å gjøre er å bestille våre tjenester, og vi sørger
            for å møte dine forventninger så langt som det er mulig.
            Fleksibilitet og effektivitet er det vi alltid garanterer gjennom
            vår tilfredsgaranti. Vi har stor lidenskap for å hjelpe bedrifter og
            privatpersoner med å rydde tid til å gjøre det de er gode på og
            brenner for, og så tar vi oss av å holde det rent og pent. Hos oss
            møter dere alltid hyggelige og serviceinstilte renholdere som setter
            kunden på første plass. Vi ønsker å skille oss ut fra alle andre
            renholds bedrifter med tjenester vi tilbyr.
          </p>
        </div>
        <ScrollLink to="services" smooth="true">
          <div className="flex flex-col items-center cursor-pointer pb-10 relative z-10 -mt-20">
            <p className="hover:underline hover:pointer mb-4 text-gray-50">
              Våre tjenester
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 vert-move text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
              />
            </svg>
          </div>
        </ScrollLink>
      </div>
    </div>
  );
};

export default LearnMore;
