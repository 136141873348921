import React, { useState } from "react";
import ServiceCardNew from "../components/ServiceCardNew";
import SpesialService from "../components/SpesialService";
import BusinessCard from "../components/BusinessCard";
import { Link as ScrollLink } from "react-scroll";
import ServiceCard from "../components/ServiceCard";
import HorizontalServiceCard from "../components/HorizontalServiceCard";

const Services = () => {
  const [rabbaterIsOpen, setRabbaterIsOpen] = useState(false);
  const rabbaterHandler = () => {};
  return (
    <div
      id="services"
      style={{}}
      className="min-h-screen py-10 px-10 flex flex-col items-center justify-center pozadina"
    >
      <p className="font-bold text-4xl mb-6">Våre tjenester</p>
      {rabbaterIsOpen ? (
        <p
          onClick={() => {
            setRabbaterIsOpen(false);
          }}
          className="w-6/12 text-center cursor-pointer font-light text-sm mb-3"
        >
          For daglig renhold (ukentlig, annen hver uke, månedlig) er hver femte
          vask gratis dersom det bestilles årlig rengjøring for påfallende år.
          Det kommer i tillegg et avslag på 10% i tillegg til hver 5. vask
          gratis i inneværende år. Dersom det ikke bestilles årlig rengjøring
          for påfallende år så teller hver 5. vask gratis til slutten av
          inneværende år.
        </p>
      ) : (
        <button
          className="shadow bg-gray-900 hover:bg-black focus:shadow-outline focus:outline-none text-white font-bold py-1 px-3 mb-1"
          onClick={() => setRabbaterIsOpen(true)}
        >
          Rabatter
        </button>
      )}

      <div class="container flex flex-wrap items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <ServiceCardNew
            slash="/daglig_renhold"
            img="https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=967&q=80"
            title="Daglig renhold"
            details="Denne tjenesten betales per time."
            price="400,00"
          />
          {/* <button className="mt-10 text-gray-50 mb-10 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <ServiceCardNew
            slash="/flyttevask"
            img="https://images.unsplash.com/photo-1613668816690-546c6fa9ad42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            title="Flyttevask"
            details="Denne tjenesten betales per m2."
            price="70,00"
          />
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <ServiceCardNew
            slash="/hovedrengjoring"
            img="https://images.unsplash.com/photo-1585541484781-f3b00759eecf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80"
            title="Hovedrengjøring"
            details="Denne tjenesten betales per m2."
            price="80,00"
          />
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <ServiceCardNew
            slash="/visningsvask"
            img="https://images.unsplash.com/photo-1496092607007-ca127e0b6a10?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=785&q=80"
            title="Visningsvask"
            details="Denne tjenesten betales per m2."
            price="80,00"
          />
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <SpesialService
            tagText="SPESIAL"
            slash="/barnerom"
            img="https://images.unsplash.com/photo-1600214859516-98999dba7cf1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80"
            title="Barnerom"
            details="Denne tjenesten betales per m2."
            price="80,00"
          />
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <SpesialService
            tagText="SPESIAL"
            slash="/terrase"
            img="https://images.unsplash.com/photo-1537289865689-48454e64980b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
            title="Terrase"
            details="Denne tjenesten betales per m2."
            price="80,00"
          />
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          <BusinessCard
            slash="/bedriftsrengjoring"
            img="https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80"
            title="Bedriftsrengjøring"
            details="Pris avtales etter avtale."
          />
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
        <div className="flex flex-col items-center justify-center">
          {/* <button className="mt-10 mb-10 text-gray-50 bg-black bg-opacity-90 hover:bg-black hover:bg-opacity-100 py-1 px-3">
            Learn More
          </button> */}
        </div>
      </div>
      <ScrollLink to="contacts" smooth="true">
        <div className="flex flex-col items-center cursor-pointer pb-10 ">
          <p className="hover:underline hover:pointer mb-4 font-bold pt-24 text-gray-900">
            Kontakt
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 vert-move text-gray-900"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
            />
          </svg>
        </div>
      </ScrollLink>
    </div>
  );
};

export default Services;
// https://images.unsplash.com/photo-1606037150583-fb842a55bae7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80
// <div id="Services" style={{ background: "#ffffff" }} className="min-h-screen py-10 px-10">
//   <div class="container flex flex-wrap mx-auto">
//     <ServiceCard
//       title="Daglig renhold"
//       imageSrc="https://images.unsplash.com/photo-1605774337664-7a846e9cdf17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=967&q=80"
//       bubbleText="Nytt tilbud"
//       priceStart="150"
//       additionalInfo="Ukentlig, annen hver uke, en gang månedlig, kun en gan."
//       desc="&#183; Støvtørking fra alle overflater <br />
//     &#183;Vasking/tørking av speil og bilder
//     <br /> &#183;Bytting av sengetoy/Re opp seng(er) <br /> &#183;
//     Vasking/tørking av alle overflater på kjøkken og skap innvending.
//     <br /> &#183; Vasking av alle hvitevarer og kjøkkenutstyr inn
//     &#183; og utvendig. <br /> &#183; Vask av kraner på bad og kjøkken
//     <br /> &#183; Støvsuging og vask av gulv og lister.
//     <br /> &#183; Vask av bad som inkluderer vask av badekar eller
//     dusj, vask av fliser innvendig i dusj, vask av toalettskål,
//     vasking av vask, gulv, speil eller glass, skap utvendig. <br />
//     &#183; Kasting av søppel fra alle rom. <br /> &#183;
//     Vask/flekkfjerning fra dører."
//     />
//     <ServiceCard
//       title="Flyttevask"
//       imageSrc="https://images.unsplash.com/photo-1606037150583-fb842a55bae7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80"
//       bubbleText="Nytt tilbud"
//       priceStart="45"
//       additionalInfo="I denne pakken av renholdstjenester trenger du ikke gjøre en separat bestilling på vask av kjøleskap/komfyr og vunduer."
//       desc="&#183;Detaljvask av bad som <br />
//             &#183;Kjøkkenskap inn- og utvendig, vask av kjøleskap, vask av &nbsp;&nbsp;komfyr samt andre hvitevarer inn- og utvendig<br />
//             &#183;Vask av vegg (inkluderer ikke fjerning av vanskelige flekker)<br />
//             &#183;Støvsuger og vask av alle gulver <br />
//             &#183;Vask av vinduer inn- og utvendig (opptil 3m høyde) <br />
//             &#183;Vask/tørking av stikkontakter og lister<br />
//             &#183;Vask av dører og karmer<br />
//             "
//     />
//     <ServiceCard
//       title="Hovedrengjøring"
//       imageSrc="https://images.unsplash.com/photo-1493953659556-556b14bdaca8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1491&q=80"
//       bubbleText="Nytt tilbud"
//       priceStart="350"
//       additionalInfo="Denne pakken inneholder tjenester som kan bestilles separat i tillegg til daglig vask, eller som en egen pakke."
//       desc="&#183;Vask av kjøleskap <br />
//             &#183;Vask av komfyr<br />
//             &#183;Vask av vinduer innvendig<br />
//             &#183;Vask av vegg/fliser<br />
//             &#183;Vask av kjøkkenskap inn-og utvendig, kjøkkenbenk, &nbsp;&nbsp;skuff/hyller. (Vi kan tilby organisering av matvarer samt &nbsp;&nbsp;annet inne i skap, og eventuelt ta bort det som er gått ut på &nbsp;&nbsp;dato. Du får alltid en liste med hvilke matvarer eller annet &nbsp;&nbsp;som vi tar bort, slik at du vet hva du må kjøpe inn)<br />
//             &#183;Vask av dør og dørhåndtak<br />
//             &#183;Vask av skap inn-og utvendig<br />
//             &#183;Stryking<br />
//             &#183;Støvsuging av møbler<br />
//             "
//     />
//     <ServiceCard
//       title="Bedriftsrengjøring"
//       imageSrc="https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80"
//       bubbleText="Nova ponuda ili specijalna ponuda?"
//       priceStart="350"
//       additionalInfo="Ovdje kao detaljnije informacije neke ili specificno nesto"
//       desc="Send oss forespørsel i mozdza neki opis?<br />"
//     />
//   </div>
//   <HorizontalServiceCard />
// </div>
