import React from "react";
import Checkmark from "../Checkmark";
import ContactButton from "../ContactButton";
import CheckmarkPlus from "../CheckmarkPlus";
import Navbar from "../Navbar";

const Hovedrengjoring = () => {
  return (
    <div>
      <Navbar option="Hjem" color="black" textColor="white" />
      <div className="flex items-center flex-col justify-center min-h-screen">
        <div className="md:flex hidden -mt-32 flex-col items-center justify-center shadow-md px-10 pb-5">
          <p className="text-3xl font-bold mt-2">Hovedrengjøring</p>
          <div className="flex items-center justify-center md:mt-10">
            <div className="flex">
              <div className="flex flex-col">
                <img
                  src="https://images.unsplash.com/photo-1585541484781-f3b00759eecf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80"
                  className="w-96"
                  alt=""
                />
                <p className="w-96 text-justify mt-3">
                  Denne pakken inneholder tjenester som kan bestilles separat i
                  tillegg til daglig vask, eller som en egen pakke.
                </p>
                <div
                  className={`flex flex-row items-center text-left flex-shrink-0 flex-wrap mb-7 mt-10`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7 mr-2 text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p className="text-xs md:text-sm" style={{ width: "70%" }}>
                    Denne pakken inkluderer alle andre tjenester som tilbys i
                    daglig rengjøring, slik som:
                  </p>
                </div>
                <CheckmarkPlus
                  position="center"
                  text="Tørking av støv på alle overflater i alle oppholdsrom "
                />
                <CheckmarkPlus
                  position="center"
                  text="Vasking av alle speil/glass/bilder"
                />
                <CheckmarkPlus
                  position="center"
                  text="Kasting av søppel (ved behov)"
                />
              </div>
              <div className="w-96 md:ml-26">
                <Checkmark position="center" text="Vask av kjøleskap" />
                <Checkmark position="center" text="Vask av komfyr" />
                <Checkmark position="center" text="Vask av vinduer innvendig" />
                <Checkmark position="center" text="Vask av vegg/fliser" />
                <Checkmark
                  position="center"
                  text="Vask av dør og dørhåndtak."
                />
                <Checkmark
                  position="center"
                  text="Vask av skap inn-og utvendig."
                />
                <Checkmark position="center" text="Stryking av klær" />
                <Checkmark position="center" text="Støvsuging av møbler" />
                <Checkmark
                  position="start"
                  text="Vask av kjøkkenskap inn-og utvendig, kjøkkenbenk, skuff/hyller. (Vi kan tilby organisering av matvarer samt annet inne i skap, og eventuelt ta bort det som er gått ut på dato. Du får alltid en liste med hvilke matvarer eller annet som vi tar bort, slik at du vet hva du må kjøpe inn)"
                />
                {/* <div
                  className={`flex flex-row items-center text-left flex-shrink-0 flex-wrap mb-7`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7 mr-2 text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p className="text-xs md:text-sm" style={{ width: "70%" }}>
                    I denne pakken inkluderer alle andre tjenester som tilbys i
                    daglig rengjøring, slik som:
                  </p>
                </div> */}
                {/* <Checkmark
                  position="center"
                  text="Tørking av støv på alle overflater i alle oppholdsrom "
                />
                <Checkmark
                  position="center"
                  text="Vasking av alle speil/glass/bilder"
                />
                <Checkmark
                  position="center"
                  text="Kasting av søppel (ved behov)"
                /> */}
              </div>
            </div>
            {/* gore lijevo */}
            {/* dolje desno */}
            <div></div>
          </div>
        </div>
          <div className="md:block hidden">
            <ContactButton />
          </div>
        {/* gore big */}
        {/* dolje small */}
        <div className="md:hidden items-center flex flex-col pb-10">
          <div className="flex flex-col items-center justify-center">
            <p className="text-3xl font-semibold mb-8">Hovedrengjøring</p>
            <img
              style={{ width: "16rem" }}
              className=""
              src="https://images.unsplash.com/photo-1585541484781-f3b00759eecf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80"
              alt=""
            />
            <p
              style={{ width: "16rem" }}
              className="mt-2 mb-8 text-sm text-justify"
            >
              Denne pakken inneholder tjenester som kan bestilles separat i
              tillegg til daglig vask, eller som en egen pakke.
            </p>
            <p className="text-xl font-bold">What you get:</p>
            <div style={{ width: "16rem" }} className="mt-5 flex flex-col">
              <Checkmark position="center" text="Vask av kjøleskap" />
              <Checkmark
                position="center"
                text="Kjøkkenskap inn- og utvendig, vask av kjøleskap, vask av komfyr samt andre hvitevarer inn- og utvendig."
              />
              <Checkmark position="center" text="Vask av vinduer innvendig" />
              <Checkmark position="center" text="Vask av vegg/fliser" />
              <Checkmark position="center" text="Vask av dør og dørhåndtak." />
              <Checkmark
                position="center"
                text="Vask av skap inn-og utvendig."
              />
              <Checkmark position="center" text="Stryking av klær" />
              <Checkmark position="center" text="Støvsuging av møbler" />
              <Checkmark
                position="center"
                text="Vask av kjøkkenskap inn-og utvendig, kjøkkenbenk, skuff/hyller. (Vi kan tilby organisering av matvarer samt annet inne i skap, og eventuelt ta bort det som er gått ut på dato. Du får alltid en liste med hvilke matvarer eller annet som vi tar bort, slik at du vet hva du må kjøpe inn)"
              />
              <div
                className={`flex flex-row items-center text-left flex-shrink-0 flex-wrap mb-7`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 mr-2 text-blue-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="text-xs md:text-sm" style={{ width: "70%" }}>
                  Denne pakken inkluderer alle andre tjenester som tilbys i
                  daglig rengjøring, slik som:
                </p>
              </div>
              <Checkmark
                position="center"
                text="Tørking av støv på alle overflater i alle oppholdsrom "
              />
              <Checkmark
                position="center"
                text="Vasking av alle speil/glass/bilder"
              />
              <Checkmark
                position="center"
                text="Kasting av søppel (ved behov)"
              />
            </div>
          </div>
          <div className="md:hidden">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hovedrengjoring;
